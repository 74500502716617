import React from "react";
import classes from "./StartPage.module.css";


const StartPage = () => {
    return (
        <div className={classes.start_page}>
            <h3>Добро пожаловать на сайт по тренировке долговременной памяти.</h3>
            <p>Запишите, что вы узнали сегодня.<br/>А на сайте можно увидеть напоминания о том, что нужно повторить.</p>
            <br/>
            <ul className={classes.list}>
                <li>
                    <p>Через день.</p>
                </li>
                <li>
                    <p>Через 3 дня.</p>
                </li>
                <li>
                    <p>Неделю спустя.</p>
                </li>
                <li>
                    <p>Через 3 недели.</p>
                </li>
                <li>
                    <p>Через месяц.</p>
                </li>
                <li>
                    <p>Через 3 месяца.</p>
                </li>
                <li>
                    <p>Через пол года.</p>
                </li>
            </ul>
            <br/>
            <p>P.S. каждое новое напоминание отсчитывает время от предыдущего.</p>
            <p> Старайтесь каждый раз вспоминать все по памяти из головы по максимуму, а то, что не помните, внимательно
                повторяйте.</p>
        </div>
    )
}

export default StartPage;
